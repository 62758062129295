<template>
  <ModalMainNew
    show-close-mobile
    secondary-background-color
    absolute-close-button
    media-type="tx"
    @close="closeModal"
  >
    <div class="mb-3 p-4 bg-gradient-to-r from-[#469034] to-[#31A913] relative">
      <div class="w-1/2 min-w-[200px] relative z-10">
        <h2 class="text-[28px] font-black mb-4 leading-[33px] mt-2">$50k Weekly Raffle</h2>
        <div class="bg-black/50 px-2 py-1 rounded-lg w-min min-w-[128px]">
          <div class="text-xs">For next reward</div>
          <Spinner v-if="isLoading" :has-logo="false" size="2xs"/>
          <div v-else class="font-bold -mt-1 tabular-nums whitespace-nowrap">{{ formatCountdown(countdown?.countdown) }}</div>
        </div>
      </div>
      <img
        src="/img/bgs/raffle-tickets.webp"
        class="absolute -right-20 xs:right-0 top-1/2 -translate-y-1/2 h-full object-contain"
        alt="tickets background"
      >
    </div>
    <Spinner v-if="isLoading" :has-logo="false" class="h-64 pt-20"/>
    <div v-else class="p-4 text-sm">
      <div class="bg-slate-600 p-2 rounded mb-3 flex flex-col gap-2">
        <div class="flex justify-between">
          <span class="text-slate-light">Next ticket</span>
          <div class="font-bold text-right"><span class="text-[#00E701]">{{ $formatMoney(progressAmount, 'USD', true) }}</span> / {{ $formatMoney(target, 'USD', true) }}</div>
        </div>
        <div class="rounded-lg h-2 bg-[#14171E] w-full relative">
          <div class="rounded-lg h-2 bg-[#00E701] absolute top-0 left-0" :style="{ width: `${progressPercent}%` }"/>
        </div>
        <div class="text-slate-light">Your numbers of entries: <strong class="text-white">{{ tickets.length }}</strong></div>
      </div>
      <p class=" text-slate-light">Wager for a chance to win your share of $50,000! Every $1,000 wagered earns a ticket. The more tickets you have, the better your chances. Winners drawn at 23:59 UTC on the last day of the month.</p>
      <div class="grid grid-cols-1 xs:grid-cols-2 gap-2 xs:gap-4 mt-4">
        <button
          class="w-full py-3 px-6 bg-slate-600 font-bold leading-tight text-white rounded-md hover:bg-slate-500 transition-all focus:bg-slate-500"
          @click.prevent.stop="handleReadMore"
        >
          Read More
        </button>
        <button
          class="w-full font-bold py-3 px-6 bg-blue-500 hover:bg-blue-400 focus:bg-blue-400 disabled:opacity-40 disabled:hover:bg-blue-500 leading-tight text-white rounded-md transition-all"
          :disabled="tickets.length === 0"
          @click.prevent.stop="showRaffleTicketsModal = true"
        >
          View Your Tickets
        </button>
      </div>
    </div>
    <LazyModalRaffleTickets v-if="showRaffleTicketsModal" @close="closeTicketsModal"/>
  </ModalMainNew>
</template>

<script setup>
import { useCountdown } from '@/composables/countdown/useCountdown';
const router = useRouter();
const { createCountdown, formatCountdown, } = useCountdown();
const { $formatMoney, } = useNuxtApp();
const uiStore = useUiStore();
const { showWeeklyRaffleModal, } = storeToRefs(uiStore);

const weeklyRaffleStore = useWeeklyRaffleStore();
const { raffleInfo, tickets, isLoading, raffleEndTime, } = storeToRefs(weeklyRaffleStore);

const countdown = ref(null);
const showRaffleTicketsModal = ref(false);
const progressPercent = computed(() => raffleInfo.value.userEntry?.gameBuyInBalance?.target?.progress ?? 0);

const target = computed(() => raffleInfo.value.userEntry?.gameBuyInBalance?.target?.value ?? 0);

const progressAmount = computed(() => {
  const remaining = raffleInfo.value.userEntry?.gameBuyInBalance?.target?.remaining ?? 0;
  return target.value - remaining;
});

function closeModal() {
  showWeeklyRaffleModal.value = false;
}

function closeTicketsModal() {
  showRaffleTicketsModal.value = false;
}

function handleReadMore() {
  closeModal();
  router.push('/promotions/50k-weekly-raffle/');
}

watch(raffleEndTime, () => {
  if (raffleEndTime.value) {
    countdown.value = createCountdown('weekly-raffle', raffleEndTime.value);
  }
}, { immediate: true, });

onMounted(async() => {
  await weeklyRaffleStore.getRaffleId();
});
</script>

<style lang="scss" scoped>
.ticket {
  border: 1px solid transparent;
  --mask:
    conic-gradient(from 42.5deg at left, #0000, #000 1deg 94deg, #0000 95deg) left/51% 13.1px repeat-y,
    conic-gradient(from -137.5deg at right, #0000, #000 1deg 94deg, #0000 95deg) right/51% 13.1px repeat-y;
  mask: var(--mask);
  position: relative;

  &:before {
    content: '';
    position: absolute;
    inset: -1px;
    background: linear-gradient(94.11deg, #E7BD3D 26.08%, #F9E15B 40.74%, #D59C2E 49.09%);
    mask: var(--mask);
    z-index: -1;
  }

  &:after {
    content: '';
    position: absolute;
    inset: 0px;
    background: #252831;
    mask: var(--mask);
    z-index: -1;
  }

  .prize {
    background: linear-gradient(94.11deg, #E7BD3D 26.08%, #F9E15B 40.74%, #D59C2E 49.09%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}
</style>
